<template>
    <div>
        <div class="login-header">
            <h1>德阳市土地超市 ｜ 用户信息</h1>
        </div>
        <div style="width:1200px;margin:0 auto;">
            <div>
                <BreadCrumb />
            </div>
            <el-container style="height: calc(100vh - 318px);">
                <el-aside width="200px" style="background: #fff;" active-text-color="#ffd04b">
                    <el-menu :default-active="$route.path" class="el-menu-vertical-demo" >
                        <el-menu-item index="/my/userinfo">
                            <router-link to="/my/userinfo"><i class="el-icon-user"></i> 我的账户</router-link>
                        </el-menu-item>
                        <el-menu-item index="/my/favorite">
                            <router-link to="/my/favorite"><i class="el-icon-star-off"></i> 我的收藏</router-link>
                        </el-menu-item>
                        <el-menu-item index="/my/land">

                            <router-link to="/my/land"><i class="el-icon-menu"></i> 我的土地</router-link>
                        </el-menu-item>
                      <el-submenu index="4">
                        <template slot="title"><i class="el-icon-message"></i> 我的需求</template>
                        <el-menu-item index="/my/need">
                          <router-link to="/my/need"><i class="el-icon-document"></i>求购信息</router-link>
                        </el-menu-item>
                        <el-menu-item index="/my/rent_land">
                          <router-link to="/my/rent_land"><i class="el-icon-document"></i>求租信息</router-link>
                        </el-menu-item>
                      </el-submenu>


                      <el-submenu index="9">
                        <template slot="title"><i class="el-icon-message"></i> 我的申购</template>
                        <el-menu-item index="/my/reservation">
                          <router-link to="/my/reservation"><i class="el-icon-document"></i> 预约看地</router-link>
                        </el-menu-item>
                        <el-menu-item index="/my/intention">
                          <router-link to="/my/intention"><i class="el-icon-document"></i> 意向报价</router-link>
                        </el-menu-item>
                      </el-submenu>

                      <el-submenu index="10">
                        <template slot="title"><i class="el-icon-message"></i> 意向申购</template>
                        <el-menu-item index="/my/reservationnew">
                          <router-link to="/my/reservationnew"><i class="el-icon-document"></i> 预约看地</router-link>
                        </el-menu-item>

                        <el-menu-item index="/my/intentionnew">
                          <router-link to="/my/intentionnew"><i class="el-icon-document"></i> 意向报价</router-link>
                        </el-menu-item>
                      </el-submenu>

                        <el-menu-item index="/my/match">
                            <router-link to="/my/match"><i class="el-icon-document"></i> 我的匹配</router-link>
                        </el-menu-item>
                      <el-submenu index="5">
                        <template slot="title"><i class="el-icon-message"></i> 我的交易</template>
                        <el-menu-item index="/my/agreement_transaction">
                          <router-link to="/my/agreement_transaction"><i class="el-icon-document"></i> 协议交易</router-link>
                        </el-menu-item>
<!--                        <el-menu-item index="/my/demand_trade">-->
<!--                          <router-link to="/my/demand_trade"><i class="el-icon-document"></i> 出租交易</router-link>-->
<!--                        </el-menu-item>-->
                        <el-menu-item index="/my/bidding_apply">
                          <router-link to="/my/bidding_apply"><i class="el-icon-document"></i> 挂牌申请</router-link>
                        </el-menu-item>
                        <el-menu-item index="/my/my_apply">
                          <router-link to="/my/my_apply"><i class="el-icon-document"></i> 挂牌竞价</router-link>
                        </el-menu-item>
                        <el-menu-item index="/my/bargain_apply">
                          <router-link to="/my/bargain_apply"><i class="el-icon-document"></i> 拍卖申请</router-link>
                        </el-menu-item>
                        <el-menu-item index="/my/my_bargain">
                          <router-link to="/my/my_bargain"><i class="el-icon-document"></i> 拍卖竞价</router-link>
                        </el-menu-item>



                      </el-submenu>
<!--                        <el-submenu index="6">-->
<!--                            <template slot="title"><i class="el-icon-message"></i> 我的合同</template>-->
<!--                            <el-menu-item index="/my/contract">-->
<!--                                <router-link to="/my/contract">网签合同查询</router-link>-->
<!--                            </el-menu-item>-->
<!--                        </el-submenu>-->


                    </el-menu>
                    <div style="text-align: center;margin-top:20px;">
                        <el-button plain style="width:160px;" @click="logout">退出登录</el-button>
                    </div>
                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </div>
        <footer2 />
    </div>
</template>
<script setup>
import LandHeader from "@/components/nav";
import footer2 from "components/footer.vue";
import LoginImg from "@/assets/img/login-img.webp";
import { RouterLink } from "vue-router";
import router from "../../router";
import { MessageBox } from "element-ui";
import CommonService from "../../service/CommonService";
import Cookies from 'js-cookie';


const logout = () => {
    MessageBox.confirm('你正在退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {

        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        localStorage.removeItem("token_type")
        localStorage.removeItem("username")
        localStorage.removeItem("exp")
        //
        Cookies.remove('refresh_token');
        Cookies.remove('token');
        //登录guest用户
        CommonService.login("guest", "123456").then(res => {
            router.push({ path: "/login" });
        });
    })
};
</script>
<style lang="scss" scoped>
.login-header {
    height: 100px;
    background-color: #0e4188;

    h1 {
        width: 1200px;
        color: #fff;
        text-align: left;
        margin: 0 auto;
        line-height: 100px;
    }
}

.login-form {
    width: 1200px;
    height: 300px;
    margin: 0 auto;
    margin-top: 50px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.el-menu-item a{
    display: block;
    width: 100%;
    height: 56px;
    line-height: 56px;

    i{
        position: relative;
        top:-2px;
    }
}

.is-active.el-menu-item{
    a{
        color:#409EFF;
    }
}

</style>